import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { Send } from '@styled-icons/feather/Send';

const SNetworkNav = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 8;
  display: flex;
  justify-content: flex-end;
  svg {
    width: ${(props) => props.theme.icon.size.large};
    height: ${(props) => props.theme.icon.size.large};
  }
  ${({ networkOpen }) => {
    if (networkOpen) {
      return css`
        z-index: 10;
      `;
    }
  }}
  @media screen and (min-width: ${(props) => props.theme.breakpoints.phablet}) {
    width: ${(props) => props.theme.spacing.baseline(8)};
    ${({ networkOpen }) => {
      if (networkOpen) {
        return css`
          width: ${(props) =>
            `calc(100% - ${props.theme.spacing.baseline(8)})`};
        `;
      }
    }}
  }
`;

const SNetwork = styled(({ ...rest }) => <motion.div {...rest} />)`
  opacity: 0;
  width: 100%;
  ul {
    width: 100%;
    height: 100%;
    align-items: center;
    background: ${(props) => props.theme.color.primary.main};
    justify-content: flex-end;
    li {
      color: white;
    }
  }
`;

const SNetworkTrigger = styled.button`
  position: relative;
  z-index: 998;
  order: 2;
  display: block;
  width: ${(props) => props.theme.spacing.baseline(8)};
  height: 4em;
  padding: ${(props) => props.theme.spacing.baseline(1, 1.5)};
  overflow: hidden;
  text-align: center;
  background: ${(props) => props.theme.color.accent.main};
  color: white;
  text-transform: uppercase;
  text-indent: -500px;
  overflow: hidden;
  &:hover {
    transition-duration: 0.2s;
    background: ${(props) => props.theme.color.primary.light};
  }
  ${({ dark }) => {
    if (dark)
      return css`
        background: white;
        color: ${(props) => props.theme.color.accent.main};
      `;
    return false;
  }}
  ${({ networkOpen }) => {
    if (networkOpen) {
      return css`
        && {
          background: ${(props) => props.theme.color.primary.light};
        }
      `;
    }
    return false;
  }}
`;

const SSendIcon = styled(Send)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.3em;
`;

export { SNetworkTrigger, SSendIcon, SNetworkNav, SNetwork };
