/* eslint-disable react/prop-types */
import Head from 'next/head';
import Page from '@/components/Page';
import { AnimatePresence } from 'framer-motion';
import { RecoilRoot } from 'recoil';
import GlobalStyle from '../GlobalStyles';
import * as gtag from '../lib/gtag';

function MyApp({ Component, pageProps, router }) {
  return (
    <>
      <Head>
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${gtag.GA_TRACKING_ID}', {
                page_path: window.location.pathname,
              });
            `,
          }}
        />
      </Head>
      <GlobalStyle />
      <RecoilRoot>
        <Page>
          <AnimatePresence /*exitBeforeEnter*/>
            <Component {...pageProps} key={router.route} />
          </AnimatePresence>
        </Page>
      </RecoilRoot>
    </>
  );
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//
//   return { ...appProps }
// }

export default MyApp;
