import { withTheme } from 'styled-components';
import { SocialLinkedin as LinkedinIcon } from '@styled-icons/foundation/SocialLinkedin';
import { SocialTwitter as TwitterIcon } from '@styled-icons/foundation/SocialTwitter';
import { Gitlab } from '@styled-icons/remix-fill/Gitlab';
import { PinterestAlt as PinterestIcon } from '@styled-icons/boxicons-logos/PinterestAlt';

import { SNetworkList, SNetworkItem } from './styles';

const Network = ({ renderExtraLink }) => {
  const items = [
    {
      id: 'link-linkedin',
      name: 'Linkedin',
      renderItem: () => <LinkedinIcon size={24} />,
      url: 'https://www.linkedin.com/in/h%C3%A9l%C3%A8ne-malespine-61884322/',
    },
    {
      id: 'link-twitter',
      name: 'Twitter',
      renderItem: () => <TwitterIcon size={24} />,
      url: 'https://twitter.com/htmlene',
    },
    {
      id: 'link-pinterest',
      name: 'Pinterest',
      renderItem: () => <PinterestIcon size={24} />,
      url: 'https://fr.pinterest.com/htmlene/',
    },
    {
      id: 'link-gitlab',
      name: 'Gitlab',
      renderItem: () => <Gitlab size={24} />,
      url: 'https://gitlab.com/Oohtmlene',
    },
  ];

  return (
    <SNetworkList data-testid="network-list">
      {items.map((item) => (
        <SNetworkItem data-testid={item.id} key={item.id}>
          <a
            href={item.url}
            title={`Go to Helene Malespine ${item.name} profile`}
          >
            {item.renderItem()}
          </a>
        </SNetworkItem>
      ))}
      <SNetworkItem>{renderExtraLink && renderExtraLink()}</SNetworkItem>
    </SNetworkList>
  );
};

export default withTheme(Network);
