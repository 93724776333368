import Head from 'next/head';

const Meta = () => {
  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta charSet="utf-8" />
      <meta
        name="keywords"
        content="Helene Malespine, front-end, developer, front, web, development, London, HTML5, CSS3, javascript, React, Sass"
      />
      <meta name="author" content="Helene Malespine" />
      <meta
        name="description"
        content="Helene Malespine, aka htmlene is a React Developer based in London working with HTML5, CSS3 and Javascript to built responsive websites"
      />
      <title>Helene Malespine - Front-End Developer in London (UK)</title>
      <link rel="icon" href="/favicon.ico" />
      <link
        href="https://fonts.googleapis.com/css2?family=Asap:wght@400;500;600&display=swap"
        rel="stylesheet"
      />
    </Head>
  );
};

export default Meta;
