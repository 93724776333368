import styled from 'styled-components';

const SNetworkList = styled(({ networkOpen, ...rest }) => <ul {...rest} />)`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const SNetworkItem = styled.li`
  display: inline-block;
  vertical-align: middle;
  margin: 0 ${(props) => props.theme.spacing.rem(2)};
  a {
    position: relative;
    display: block;
    color: inherit;
    &:hover {
      color: ${(props) => props.theme.color.caption};
    }
  }
`;

export { SNetworkList, SNetworkItem };
