import styled, { css, keyframes } from 'styled-components';
import { motion } from 'framer-motion';

const rotate = keyframes`
  0% { transform: rotate(0); } 
  100% { transform: rotate(-180deg); }
`;
const rotateBefore = keyframes`
  0% { transform: rotate(0); }
  100% { transform: rotate(45deg) scale(0.8, 1) translate(7px, -1px); }
`;
const rotateAfter = keyframes`
  0% { transform: rotate(0); }
  100% { transform: rotate(-45deg) scale(0.8, 1) translate(7px, 1px); }
`;

const SNav = styled.nav`
  position: absolute;
  display: flex;
  overflow: hidden;
  z-index: 9;
  @media screen and (min-width: ${(props) => props.theme.breakpoints.phablet}) {
    width: ${(props) => props.theme.spacing.baseline(8)};
    ${({ navOpen }) => {
      if (navOpen) {
        return css`
          width: ${(props) =>
            `calc(100% - ${props.theme.spacing.baseline(8)})`};
          z-index: 9;
        `;
      }
    }};
  }
`;

const SNavHamburger = styled.span`
  position: absolute;
  display: block;
  top: 50%;
  height: 2px;
  width: ${(props) => props.theme.spacing.baseline(4)};
  background: ${(props) => props.theme.color.accent.main};
  transition: background 0s 0.3s;
  border-radius: 3px;
  &::before,
  &::after {
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    content: '';
    background-color: inherit;
    transition-duration: 0.2s;
    border-radius: 3px;
  }
  &::before {
    top: -${(props) => props.theme.spacing.baseline(1)};
  }
  &::after {
    bottom: -${(props) => props.theme.spacing.baseline(1)};
  }
  ${({ trigger }) => {
    if (trigger === 'back') {
      return css`
        height: 2px;
        width: ${(props) => props.theme.spacing.baseline(3.5)};
        transition: background 0s 0.3s;
        border-radius: 3px;
        animation: ${rotate} 0.25s 0.2s forwards;
        transform-origin: 50% 50% 0;
        &::before,
        &::after {
          transition-delay: 0.3s, 0s;
        }
        &::before {
          top: -12px;
          animation: 0.25s ${rotateBefore} 0.25s forwards;
        }
        &::after {
          bottom: -12px;
          animation: 0.25s ${rotateAfter} 0.25s forwards;
        }
      `;
    }
  }}
`;

const SNavTrigger = styled.button`
  position: relative;
  z-index: 9;
  display: block;
  width: ${(props) => props.theme.spacing.baseline(8)};
  height: 4em;
  padding: ${(props) => props.theme.spacing.baseline(0, 2)};
  overflow: hidden;
  text-align: center;
  background: white;
  color: white;
  text-transform: uppercase;
  text-indent: -500px;
  overflow: hidden;
  border: none;
  transition-duration: 0.2s;
  &:hover {
    transition-duration: 0.2s;
    background: ${(props) => props.theme.color.primary.light};
    ${SNavHamburger} {
      background: white;
    }
  }
  ${({ navOpen }) => {
    if (navOpen) {
      return css`
        && {
          background: ${(props) => props.theme.color.primary.light};
          ${SNavHamburger} {
            background: transparent;
            &::before,
            &::after {
              transition-duration: 0.2s;
              background: white;
            }
            &::before {
              top: 0;
              transform: rotate(45deg);
            }
            &::after {
              bottom: 0;
              transform: rotate(-45deg);
            }
          }
        }
      `;
    }
  }}
`;

const SNavList = styled(({ ...rest }) => <motion.ul {...rest} />)`
  display: flex;
  overflow: hidden;
  width: 100%;
  align-items: center;
  text-align: center;
  background: ${(props) => props.theme.color.primary.main};
  opacity: 0;
`;

const SNavItem = styled.li`
  display: inline-block;
  vertical-align: middle;
  color: white;
  a {
    display: block;
    color: inherit;
    padding: ${(props) => props.theme.spacing.rem(1, 2)};
    text-transform: uppercase;
    line-height: 1;
    &:hover {
      color: ${(props) => props.theme.color.caption};
      text-decoration: none;
    }
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints.tablet}) {
    a {
      padding: ${(props) => props.theme.spacing.rem(2, 3)};
    }
  }
`;

export { SNavTrigger, SNavHamburger, SNav, SNavList, SNavItem };
