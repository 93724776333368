import styled from 'styled-components';
import { motion } from 'framer-motion';

const SSnackbar = styled(({ ...rest }) => <motion.div {...rest} />)`
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.spacing.rem(2, 3)};
  background: ${(props) => props.theme.color.body};
  color: white;
`;

const SMessage = styled.p`
  margin-right: ${(props) => props.theme.spacing.rem(2)};
`;

const SDismiss = styled.button`
  width: 2em;
  color: white;
  background: none;
`;

export { SSnackbar, SMessage, SDismiss };
