import { keyframes } from 'styled-components';

const theme = {
  color: {
    body: '#0D1E28',
    caption: '#6F7173',
    primary: {
      main: '#0D1E29',
      light: '#1f3442',
      dark: '#000000',
      rgb: '13, 30, 40',
    },
    accent: {
      main: '#ff5252',
      light: '',
      dark: '',
      rgb: '255, 82, 82',
    },
    muted: {
      main: '#F3F5F6',
      light: '',
      dark: '#E5E5E5',
    },
    border: '#E5E5E5',
    danger: '#d10000',
    gradient: {
      accent:
        'rgba(0, 0, 0, 0) linear-gradient(153deg, rgba(255,82,82,1) 50%, rgba(255,130,81,1) 100%) repeat scroll 0% 0%;',
    },
  },
  font: {
    size: {
      small: `${(1 / 16) * 14}rem`,
      body: `${(1 / 16) * 16}rem`,
      medium: `${(1 / 16) * 20}rem`,
      large: `${(1 / 16) * 24}rem`,
      xlarge: `${(1 / 16) * 28}rem`,
      h1: `${(1 / 16) * 36}rem`,
      h2: `${(1 / 16) * 28}rem`,
      h3: `${(1 / 16) * 24}rem`,
      h4: `${(1 / 16) * 20}rem`,
    },
    weight: {
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
    letterSpacing: '1.1px',
  },
  icon: {
    size: {
      xsmall: `${(1 / 16) * 16}rem`,
      small: `${(1 / 16) * 24}rem`,
      medium: `${(1 / 16) * 32}rem`,
      large: `${(1 / 16) * 40}rem`,
      xlarge: `${(1 / 16) * 48}rem`,
    },
  },
  spacing: {
    baseline: (...arr) => {
      const spacing = arr
        .map((n) => `${n * 8}px`)
        .toString()
        .replace(/,/gi, ' ');
      return spacing;
    },
    rem: (...arr) => {
      const spacing = arr
        .map((n) => `${(1 / 16) * n * 8}rem`)
        .toString()
        .replace(/,/gi, ' ');
      return spacing;
    },
  },
  breakpoints: {
    mobile: '320px',
    phablet: '600px',
    tablet: '768px',
    tabletLandscape: '980px',
    laptop: '1280px',
    desktop: '1440px',
    lg: '1600px',
    xlg: '1700px',
  },
  layout: {
    wrapperMobile: {
      position: 'relative',
      padding: '12vh 5vw 0',
      width: '100%',
    },
    wrapper: {
      position: 'relative',
      padding: '15vh 10vw 0',
      width: '100%',
    },
    header: {
      position: 'relative',
      margin: '0 0 5vh',
      '&::after': {
        display: 'block',
        margin: '3em auto 0px',
        height: '1px',
        width: '300px',
      },
    },
    routeMotion: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
    },
  },
  transition: {
    default: '0.5s',
    fast: '0.25s',
  },
  radius: '2px',
  animation: {
    bounce: () => keyframes`
    from, 20%, 53%, 80%, to { animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000); transform: translate3d(0,0,0) translate(-50%, 0); }
    40%, 43% { animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060); transform: translate3d(0, -30px, 0) translate(-50%, 0); }
    70% { animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060); transform: translate3d(0, -15px, 0) translate(-50%, 0); }
    90% { transform: translate3d(0,-4px,0) translate(-50%, 0); }
  `,
  },
};

export default theme;
