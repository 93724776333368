import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import theme from '@/styling/theme';

const GlobalStyle = createGlobalStyle`
  ${normalize}
  * {
    box-sizing: border-box;
  }
  html, body, #__next {
    width: 100%;
  }
  html {
    height: 100%;
    font-size: 87.5%;
    @media (min-width: 1024px) {
      font-size: 93.75%;
    }
    @media (min-width: 1600px) {
      font-size: 100%;
    }
  }
  body, #__next {
    min-height: 100vh;
  }
  body {
    font-family: 'Asap', sans-serif;
    line-height: 1.5;
    font-size: ${theme.font.size.body};
    color: ${theme.color.body};
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: 400;
  }
  button{
    outline: none;
    border: none;
    padding: 0;
    background: none;
    cursor: pointer;
    &:hover, &:focus {
      outline: none;
    }
  }
  input[type="submit"] {
    &:hover, &:focus {
      outline: none;
    }
  }
  p {
    margin: 0;
  }
  a {
    text-decoration: none;
    outline: none;
    color: ${theme.color.caption};
    &:hover, &:focus {
      outline: none;
      text-decoration: underline;
    }
  }
  img {
    max-width: 100%;
    height: auto;
  }
  strong {
    font-weight: normal;
  }
`;

export default GlobalStyle;
