import { useState } from 'react';
import { oneOf } from 'prop-types';
import { useRouter } from 'next/router';
import { useRecoilState } from 'recoil';
import { pageFlow } from '@/recoil/atoms';
import { withTheme } from 'styled-components';
import Link from '@/components/Link';
import { getNavItemProps, toKey } from '@/helpers';
import { SNavTrigger, SNavHamburger, SNav, SNavList, SNavItem } from './styles';

const MainNav = ({ trigger }) => {
  const router = useRouter();
  const [_, setPageFlow] = useRecoilState(pageFlow);
  const [navOpen, setNavOpen] = useState(false);
  const navItems = ['home', 'about', 'skillsset', 'work'];

  const handleClick = (e) => {
    setPageFlow({ origin: router.pathname.slice(1), effect: 'fade' });
    setNavOpen(!navOpen);
  };

  const handleTrigger = () => {
    if (trigger === 'back') {
      setPageFlow({ origin: '', target: '' });
      router.push('/');
    } else {
      setNavOpen(!navOpen);
    }
  };

  return (
    <SNav data-testid="main-nav" role="navigation" navOpen={navOpen}>
      <SNavTrigger
        data-testid="main-nav-trigger"
        navOpen={navOpen}
        role="button"
        onClick={handleTrigger}
        trigger={trigger}
      >
        {trigger === 'back' ? 'Back Home' : 'Menu'}
        <SNavHamburger trigger={trigger} />
      </SNavTrigger>
      {navOpen && (
        <SNavList
          data-testid="main-nav-list"
          animate={{ x: ['-100%', '0%'], opacity: [0.7, 1] }}
          transition={{ times: [0, 0.3], duration: 0.3, ease: 'easeInOut' }}
        >
          {navItems.map((item) => (
            <SNavItem key={toKey(item)}>
              <Link {...getNavItemProps(item)} handleClick={handleClick} />
            </SNavItem>
          ))}
        </SNavList>
      )}
    </SNav>
  );
};

MainNav.propTypes = {
  trigger: oneOf(['back', 'nav']).isRequired,
};

export default withTheme(MainNav);
