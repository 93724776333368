import { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { useRouter } from 'next/router';
import { node } from 'prop-types';
import { useRecoilValue, useRecoilState } from 'recoil';
import { windowSize, pageFlow, snackbarState } from '../../recoil/atoms';
import theme from '../../styling/theme';
import Meta from '../Meta';
import MainNav from '../MainNav';
import NetworkNav from '../NetworkNav';
import Snackbar from '../Snackbar';

const Page = ({ children }) => {
  const [screenSize, setWindowSize] = useRecoilState(windowSize);
  const [snackbar] = useRecoilState(snackbarState);
  const [navTrigger, setNavTrigger] = useState('nav');
  const router = useRouter();
  const flow = useRecoilValue(pageFlow);

  const handleResize = () => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    if (process.browser) {
      window.addEventListener('resize', handleResize);
    }
    return function cleanup() {
      window.removeEventListener('resize', handleResize);
    };
  });

  const { origin, effect } = flow;

  useEffect(() => {
    if (screenSize.width < 768 && effect === 'scale' && origin === 'home') {
      setNavTrigger('back');
    } else {
      setNavTrigger('nav');
    }
  }, [screenSize, effect, origin]);

  return (
    <>
      <Meta />
      <ThemeProvider theme={theme}>
        <MainNav trigger={navTrigger} />
        <NetworkNav dark={router.pathname === '/about'} />
        {children}
        {snackbar.isOpen && <Snackbar />}
      </ThemeProvider>
    </>
  );
};

Page.propTypes = {
  children: node.isRequired,
};

export default Page;
