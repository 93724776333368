import { useState } from 'react';
import { bool } from 'prop-types';
import { withTheme } from 'styled-components';
import { getNavItemProps } from '@/helpers';
import Link from '@/components/Link';
import Network from '../Network';
import { SNetworkTrigger, SSendIcon, SNetworkNav, SNetwork } from './styles';

const NetworkNav = ({ dark }) => {
  const [networkOpen, setNetworkOpen] = useState(false);
  return (
    <SNetworkNav data-testid="network-nav" networkOpen={networkOpen}>
      {networkOpen && (
        <SNetwork
          data-testid="network-nav"
          animate={{ x: ['100%', '0%'], opacity: [0, 1] }}
          transition={{ times: [0, 0.3], duration: 0.3, ease: 'easeInOut' }}
        >
          <Network
            renderExtraLink={() => <Link {...getNavItemProps('contact')} />}
          />
        </SNetwork>
      )}
      <SNetworkTrigger
        data-testid="network-trigger"
        networkOpen={networkOpen}
        role="button"
        onClick={() => setNetworkOpen(!networkOpen)}
        dark={dark}
      >
        Keep in touch
        <SSendIcon />
      </SNetworkTrigger>
    </SNetworkNav>
  );
};

NetworkNav.propTypes = {
  dark: bool,
};

NetworkNav.defaultProps = {
  dark: false,
};

export default withTheme(NetworkNav);
