import { useEffect } from 'react';
import { withTheme } from 'styled-components';
import { useRecoilState } from 'recoil';
import { Close } from '@styled-icons/material-outlined/Close';
import { snackbarState } from '../../recoil/atoms';

import { SSnackbar, SMessage, SDismiss } from './styles';

const Snackbar = () => {
  const [snackbar, setSnackbar] = useRecoilState(snackbarState);

  const clearSnackbar = () => {
    setSnackbar({ isOpen: false, message: '', error: false });
  };

  useEffect(() => {
    const timer = setTimeout(() => clearSnackbar(), 4000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <SSnackbar
      data-testid="snackbar"
      initial={{ x: '100%' }}
      animate={{ x: 0 }}
      exit={{ x: '100%' }}
      isOpen={snackbar.isOpen}
    >
      <SMessage>{snackbar.message}</SMessage>
      <SDismiss onClick={clearSnackbar}>
        <Close />
      </SDismiss>
    </SSnackbar>
  );
};

export default withTheme(Snackbar);
